.inquiry {
  background: whitesmoke;
  overflow: hidden;
  padding: 15px;
  height: 103vh;
}
.inquiry_card {
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* margin-top: -80px; */
  /* margin-top: 50px;
    margin-bottom: 50px; */
}
.inquiry_cardtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important ;
  line-height: 140% !important;
  color: #212121 !important;
  padding: 17px 0;
}
.inquiry_email {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px !important;

  /* Grayscale/gray-900 */

  color: #212121 !important;
}
.inquiry_emailinpt {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0.25px !important;
  color: #757575 !important;
  box-shadow: none !important;
}
.inquiry_suport {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px !important;

  /* Grayscale/gray-900 */

  color: #212121 !important;
}
.inquiry_cardBody {
  width: 60%;
}
.inquiry_suportinpt {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0.25px !important;
  color: #757575 !important;
  box-shadow: none !important;
}
.inquiry_attachment {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px !important;

  /* Grayscale/gray-900 */

  color: #212121 !important;
}
.inquiry_attachmentMute {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
}
.inquiry_descrip {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px !important;

  /* Grayscale/gray-900 */

  color: #212121 !important;
}
.form-control {
  border-radius: 0.15rem !important;
}
.btn-group-lg > .btn,
.btn-lg {
  border-radius: 0.1rem !important;
}
.card-body {
  /* padding: 1rem 0rem !important; */
}
.inquiry_descripTextarea {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0.25px !important;
  color: #757575 !important;
  box-shadow: none !important;
}
.inquiry_btn {
  background: #103a56 !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  color: #ffffff !important;
}
.upload {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px -15px;
}

.delete-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: "rgb(255, 255, 255)";
  border-width: 0;
  border-radius: 100%;
  margin-left: -20px;
  height: 23px;
  width: 23px;
  margin-top: -10px;
  box-shadow: 1px 0px 4px 1px #aaa9a9 !important;
}
