#cutThrough {
  fill: #000 !important;
}

#cutThrough>path {
  fill: #000 !important;
}

.cuttingThrough {
  position: absolute;
  transform: rotate(-117deg);
  left: 104px;
  top: 36px;
  background: #000;
  width: 3px;
  height: 40px;
  border: 0px solid #000;
}



div.formSalon>div.form-check>input.form-check-input {
  border: 0.5px solid black !important
}

.dropMenus {
  width: 170px !important;
  border: 1px solid rgba(0, 0, 0, .15) !important;
}

div.dropMenuSalon>button {
  background: none;
  border: none;
}

div.dropMenuSalon>button:hover {
  background: none;
  border: none;
}

div.dropMenuSalon.show.dropdown>button {
  background: none;
  border: none;
}

div.dropMenuSalon.dropdown>button {
  background: none;
  border: none;
}


.dropMenuSalon {
  padding-top: 65%
}

button.dropMenu {
  background: none;
  border: none;
  padding: 0px;
}

div.casesDrop>button {
  background: none !important;
}

button.dropMenu:hover {
  background: none;
  border: none;
  padding: 0px;
}

button.dropMenu:active {
  background: none;
  border: none;
  padding: 0px;
}

.infoSaloon {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.divSlots {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #F8F9FB;
  border-radius: 5px;
  padding: 1% 2%;
  margin-bottom: 1%;
}

.slotsDay {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: #161616;
}

.slotsDiv {
  margin-top: 4px;
  padding-left: 5px;
  border: 1px solid #E8E9EC;
  background: white;
  height: 25px;
  width: 62px;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 11px;
}

.slotsTime {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 1% 4% 0% 11%;
}

.switchSlot {
  position: absolute;
  right: 5px;
  top: 13px;
}

.slotUnavail {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A8A8A8;
  margin-top: 4px;
  margin-left: 20px;
}

.btn.btnSlot {
  background: #2892D7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  height: 45px;
}

.btn.btnSlot:hover {
  color: white;
}

.inviteImg {
  margin-top: 10%;
  display: flex;
  justify-content: center;
}

.inviteH {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212121;
}

.btn.btnInvite {
  position: absolute;
  top: 0;
  right: 7px;
  top: 8px;
  height: 39px;
  background: #2892D7;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
}

.btn.btnInvite:hover {
  color: white;
}

.inviteLetterH {
  margin-top: 2%;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}

.inviteIpt {
  padding: 0% 17% 0% 2%;
  height: 55px;
  background: #F8F8F8;
  border-radius: 4px;
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #757575;
}

.divIpt {
  width: 85%;
  margin: 0 auto;
  margin-top: 10% !important;
}

.lineInvite {
  border: 0.5px solid #9E9E9E;
  height: 1%;
  width: 50%;
  background-color: #9E9E9E;
}

.rowInvite {
  width: 90%;
  margin: 0 auto !important;
}

.invitationIpt {
  padding-left: 5%;
  height: 45px;
  width: 100%;
  border: 1px solid #9E9E9E;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #757575;
}

.saloonCard {
  background: #f8f9fb !important;
  border: none !important;
}

.saloonCardp {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #9e9e9e;
}

.saloonCardTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #222222;
}

.saloonHeadings {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212121;
}

.saloonCardIpt {
  padding-left: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  height: 40px;
  border-radius: 2px;
}

.saloonContract {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}
button.btn.btnSaloon:disabled {
  color: white;
  border: 0;
}
.btn.btnSaloon {
  background: #2892d7 !important;
  width: 192px;
  height: 46px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fff !important;
}

.btn.btnSaloon:hover {
  color: white;
}

.saloonBtnDiv {
  justify-content: left;
  display: flex;
}

.btn.btnSendLink {
  height: 45px;
  background: #2892D7;
  border-radius: 4px;
  font-weight: 400;
  width: 20%;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
}

.btn.btnInvite:hover
{
  color: white;
  background: #23a7fd;
}
.btn.btnSendLink:hover {
  color: white;
  background: #23a7fd;
}
.addAnother {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #2892D7;
  cursor: pointer;
}

.linkSend {
  display: flex;
  justify-content: space-between;
  width: 85% !important;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container.containerInvite {
    max-width: inherit !important;
  }
}

.svgInvite {
  margin-right: 10px;
}

.divSync {
  border-bottom: 2px solid #E0E0E0;
  margin-top: -1.5px;
}

.form-switch .form-check-input:checked {
  background-color: #43A047 !important;

}

@media screen and (max-width: 480px) {
  .responsiveInvite {
    display: none;
  }

  .divIpt {
    width: 100%;
  }

  .svgInvite {
    margin-right: 0px;
  }

  .colInvite {
    flex: none !important;
  }

  .inviteH {
    margin-top: 5%;
  }

  .btn.btnSendLink {
    height: 30px;
    width: auto;
  }

  .uploadInvite {
    position: initial !important;
  }
}

.iconActive>path {
  fill: #000000 !important;
}

.iconActiveInG>g>path {
  fill: #000000 !important;
}

.iconNon>path {
  fill: #9e9999 !important;
}

.iconNonInG>g>path {
  fill: #9e9999 !important;
}

.iconNonNonInG>path {
  fill: black !important;
}

.salonInfo {
  width: 85%;
  margin: 0 auto;
}

.iconNonNonInG>path:hover {
  fill: white !important;
}

/* form.switchSlot>div.switch>input {
  border: none !important
} */

@media screen and (max-width: 480px) {
  .dropMenuSalon {
    padding-top: 55%
  }

  .salonInfo {
    width: 100% !important;
  }

  .rowInvite {
    width: 100%;
  }
}