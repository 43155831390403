.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 350px;
}
ul.react-multi-carousel-track {
  padding: 30px 0px !important;
}

ul.react-multi-carousel-dot-list {
  padding: 13px !important;
}
.swiper-slide img {
  display: block;
  width: 100%;
}

.sliiess {
  display: flex;
  overflow: scroll;
  width: 70%;
  margin: 0 auto;
  flex-wrap: inherit;
}

.imgg img {
  width: 100px;
  align-items: center;
  margin: 0 auto;
}

.imgg {
  text-align: center;
  align-items: center;
}

.contentss,
.contentname {
  text-align: center;
}

.contentss h5 {
  font-size: 15px;
  margin: 22px;
  line-height: 25px;
  margin-bottom: 35px;
  font-style: italic;
  min-height: 200px !important;
}

.contentname p {
  margin: 0;
}

p.rrefff {
  color: rgba(158, 158, 158, 1);
  font-size: 12px;
}

p.namess {
  font-size: 20px;
  font-weight: 600;
}

.itemss {
  border: 0.5px solid rgba(238, 238, 238, 1);
  padding: 43px 0px;
  margin: 17px;
  border-radius: 16px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

li.clicking {
  border: 4px solid rgba(40, 146, 215, 1);
  border-bottom: 4px solid rgba(40, 146, 215, 1) !important;

  border-radius: 50%;
  z-index: 999;
  transform: scale(1.5);
  /* zoom: 50%; */
}

li.diss {
  padding: 0px 8px;
}

li.marg {
  margin: 0px 8px;
}

.arrow {
  position: absolute;
  top: 50%;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.carousel-item {
  padding: 71px 0px;
  border-bottom: 0px !important;
}
li.active.clicking.marg.wiidthh {
  margin: 5px 10px;
}

/* ul.react-multi-carousel-dot-list {
  padding: 18px !important;
} */

/* .carousel-inner {
  background: #fff;
  padding: 17px 0px;
} */
@media screen and (max-width: 992px) {
  ul.react-multi-carousel-dot-list {
    justify-content: center;
    overflow-x: scroll;
  }
}
