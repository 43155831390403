div.controlModalWidth>div.modal-dialog>div.modal-content {
  width: 600px
}

.btn.btnViewJob {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  background: #2892D7;
  border-radius: 4px;
}
button.btn.btnDone.me-2:active,button.btn.btnDone.me-2:focus,button.btn.btnDone.me-2:not(active){
  
    background-color: #DDF6EC;
    border: 1px solid #DDF6EC

}
button.btn.btnClose.me-2:active,button.btn.btnClose.me-2:focus,button.btn.btnClose.me-2:not(active){
background-color: #FDE6E6;
border: 1px solid #FDE6E6
}


.imgFavSalon {
  margin-right: 5% !important;
  margin-left: 5%;
}

.btn.btnReferJob {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #2892D7;
  border: 1px solid #2892D7;
}

.btn.btnReferJob:hover {
  color: #2892D7;
}

.btn.btnViewJob:hover {
  color: white
}

.imgMyJob {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.btn.btnDone {
  background-color: #DDF6EC;
  height: 32px;
  cursor: pointer;

}

.btn.btnClose {
  background-color: #FDE6E6;
  height: 32px;
  cursor: default;
}

.btn.btnEye {
  background-color: #2892D7;
  height: 32px
}

.modalJobtitle {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 140% !important;
  color: #000000;
}

.jobDesc {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.2px !important;
  color: #000000 !important;
}

.respHeading2 {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #202430;
  opacity: 0.5;
}
button.btn.btnDone.me-2.cusbttn.Accpt {
  width: 92px;
  color: #198754;
  padding: 0px;
  cursor: auto;
  font-size: 14px;
}
button.btn.btnDone.me-2.cusbttn.rejec {
  width: 92px;
  color: #dc3545;
  background: #FDE6E6;
  padding: 0px;
  cursor: auto;
  font-size: 14px;
}

.JobRole2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* display: flex; */
  align-items: center;
  color: #212121;
}

.divSecond2 {
  display: flex;
  justify-content: space-between;
  padding: 3% 0% 1% 1%;
  border-bottom: 2px solid #e0e0e0;
  border-top: 2px solid #e0e0e0;
}

.jobModalHead {
  display: flex;
  justify-content: space-between;
  padding: 3% 0%;
  border-bottom: 2px solid #e0e0e0;
  border-top: 2px solid #e0e0e0;
  text-align: center;
}

.descJobModal {
  width: 96%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}

.buttonTxting {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
}

.searchImg {
  position: absolute;
  top: 4.8%;
  right: 26.5%;
  z-index: 1;
}

.closeIcons.btn-close {
  position: absolute !important;
  top: 2% !important;
  right: 3% !important;
}


@media screen and (max-width: 858px) {
  .respList {
    font-size: 11px !important;
  }

  .btn.btnViewJob {
    font-size: 12px;
  }

  .btn.btnReferJob {
    font-size: 12px;
  }
}

@media screen and (max-width: 816px) {
  .respList {
    font-size: 10px !important;
  }

  .btn.btnViewJob {
    font-size: 12px;
  }

  .btn.btnReferJob {
    font-size: 12px;
  }
}

@media screen and (max-width: 796px) {
  .respList {
    display: none;
  }

  .respTableJob {
    display: none;
  }

  .respHeading1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #202430;
    opacity: 0.5;
  }

  .respDiv1 {
    border-bottom: 1px solid #D6DDEB;
  }

  .JobRole1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #212121;
  }

  .divFirst1 {
    display: flex;
    justify-content: space-between;
    padding: 6% 3% 0% 4%;
  }

  .divSecond1 {
    display: flex;
    justify-content: space-between;
    padding: 7% 10% 1% 5%;
  }

  .paginationShow1 {
    display: contents;
  }

  .JobIpt1 {
    position: inherit;
    width: 100%;
  }

  .JobPageCard1 {
    justify-content: center;
  }

  .pages1 {
    padding-left: 43% !important;
  }



  .btn.btnReferJob {
    width: 100%;
    margin-top: 0% !important;
  }

  .btn.btnViewJob {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .JobRole1 {
    font-size: 12px;
  }
}

@media screen and (min-width: 796px) {
  .responsiveTableMyJob {
    display: none;
  }
}

@media screen and (max-width: 920px) {

  div.controlModalWidth>div.modal-dialog>div.modal-content {
    width: 100%
  }

  .btn.btnReferJob {
    margin-top: 5%;
  }
}

@media screen and (max-width: 992px) {
  .searchImg {
    right: 28% !important;
  }
}

@media screen and (max-width: 1030px) {
  .searchImg {
    right: 27.3%;
  }
}

@media screen and (max-width: 402px) {
  .modalJobtitle {
    font-size: 18px !important;
  }
}