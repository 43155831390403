.email-authenticate {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #222222;
}

.email-authenticate-p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #757575;
}

.container-authenticate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.span2 {
  color: #2892d7;
}

.landingCategory {
  padding: 30px 0px;
}

.divLanding {
  padding: 30px 0px;
}

.paragraph.btm {
  margin: 0 auto;
  text-align: center;
}

.poster {
  background-image: url("./../../Assets/images/Poster1.png");
  height: 100vh;
  width: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn.btnPoster {
  margin-top: 2%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #212121;
  background-color: white;
}

.h1poster {
  font-weight: 600;
  font-size: 48px;
  line-height: 140%;
  color: #ffffff;
}

.h2poster {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.6;
}

.posterDiv {
  margin-top: 20%;
  width: 80%;
}

.heroBanner {
  background-image: url("./../../Assets/images/header.svg");
  height: 850px;
  width: auto;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroBanner h2 {
  color: #fff !important;
}

.heroBanner p {
  color: #ffff !important;
}

.heroBanner .handpicc {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.landingCategory {
  padding: 30px 0px;
}

.divLanding {
  padding: 30px 0px;
}

.fleexuucard {
  display: flex;
  align-items: center;
}

.textcard {
  margin-left: 20px;
}

.indiviuulBanner .bannerdash {
  margin: 0 auto;
  padding: 0;
}

.indiviuulBanner .backImg {
  height: 600px;
}

.indiviuulBanner .conten2 {
  width: 33%;
}

.indiviuulBanner h2 {
  font-size: 40px !important;
}

.mainpricing {
  padding: 40px 0px;
}

.indiviuulBanner p {
  font-size: 18px !important;
}

.title_fflex {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
    width: 100%;
}

.wiidthh img {
  width: 50px;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_recent.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: rgba(16, 58, 86, 1);
  color: #fff;
  font-size: 14px;
  /* border: 1px solid; */
  border-radius: 0px;
  border: 1px solid rgba(16, 58, 86, 1);

}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_feature.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: #f5f5f5;
  font-size: 14px;
  color: #000;
  border: 1px solid rgba(16, 58, 86, 1);
  border-radius: 0px;

}

.inffoitem button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_feature.css-1ujsas3 {
  color: #000;
  border: 1px solid rgba(16, 58, 86, 1);
  border-radius: 0px;
}

.inffoitem button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_recent.css-1ujsas3 {
  background: #103a56;
  border: 1px solid #103a56;
  color: #fff;
  margin-left: 0px;
  border-radius: 0px;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.ownerPost.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: aquamarine;
}

.seall {
  text-align: center;
  padding: 20px 0px;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.seeall.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: #479ad4;
  font-size: 12px;
  padding: 7px 24px;
  color: #fff;
}

p.MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom.css-1tqv6h6 {
  color: #ffff;
}

.inffoitem button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_feature.css-1ujsas3 {
  color: #000;
  border: 1px solid rgba(16, 58, 86, 1);
  border-radius: 0px;
}

.inffoitem button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_recent.css-1ujsas3 {
  background: #103a56;
  border: 1px solid #103a56;
  color: #fff;
  margin-left: 0px;
  border-radius: 0px;
}

.seall button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.seeall.css-1ujsas3 {
  background: #479ad4;
  font-size: 12px;
  padding: 7px 24px;
  color: #fff;
}

.categirres .col {
  width: 25%;
}

@media screen and (max-width: 1400px) {
  .indiviuulBanner .backImg {
    height: 450px;
  }
}

@media screen and (max-width: 1080px) {
  .indiviuulBanner .backImg {
    height: 300px;
  }
}

@media screen and (max-width: 992px) {
  .categirres .col {
    width: 100%;
  }

  .categirres {
    display: flex !important;
    overflow-x: auto !important;
    white-space: nowrap !important;
    flex-wrap: inherit !important;
  }

  .title_fflex {
    display: block;
    padding: 35px;
  }

  .inffoitem {
    text-align: center;
  }

  .inffoitem h2 {
    font-size: 24px !important;
    margin-bottom: 21px;
    font-weight: 800;
  }

  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.btn_feature.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-right: 23px;
  }

  .heroBanner .flexxsearch {
    padding: 20px !important;
  }

  .indiviuulBanner .conten2 {
    width: 170px;
  }

  .indiviuulBanner .conten2 {
    top: 13%;
    left: 50%;
  }

  .searchdiv {
    padding: 0px;
  }

  .indiviuulBanner p {
    font-size: 9px !important;
  }

  .heroBanner {
    height: 640px;
  }

  .flexxsearch {
    padding: 20px 6px;
  }
}

@media screen and (max-width: 600px) {
  .indiviuulBanner .backImg {
    height: 200px;
  }
}