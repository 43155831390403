.minWidth44 {
  min-width: 44% !important;
}
.favWeb {
  display: block;
  min-width: 44% !important;
}

.favMob {
  display: none;
}
.infoImg.owner img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.cliickss {
  cursor: pointer;
}
.elipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.width-45 {
  width: 45% !important;
}
.custom-offer-btn {
  text-transform: uppercase;
  background: #2892d6;
  padding: 8px 35px;
  border: 1px solid;
  min-width: 44% !important;
  min-height: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  outline: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.custom-add-fav-btn {
  text-transform: uppercase;
  background: #fff;
  color: #9e9e9e;
  padding: 8px 15px;
  min-width: 157px !important;
  min-height: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  min-width: 100% !important;
  border: 1px solid;
  outline: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.custom-add-fav-btn:hover {
  text-transform: uppercase;
  background: #2892d5;
  color: #fff;
  padding: 8px 15px;
  min-width: 100% !important;
  min-height: 42px;
}
.custom-fav-btn {
  text-transform: uppercase;
  background: #2892d5;
  color: white;
  padding: 8px 15px;
  min-width: 100% !important;
  min-height: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  border: 1px solid;
  outline: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
@media screen and (max-width: 600px) {
  .favWeb {
    display: none;
  }

  .favMob {
    display: block;
  }

  .innerbtn {
    justify-content: space-around;
    align-items: center;
  }

  button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.apllyy.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    padding: 10px 70px;
    margin-right: 9px;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 36px;
  }
}
