.imgJob {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.injectionButton {
  border-radius: 50% !important;
  background-color: #43A047 !important;
  height: 27px;
  width: 10px;
  border: 2px solid white !important;
  margin: 0% 3% -12% -15% !important;
}

.injectImg {
  height: 24px;
  margin: 0px 12px -30px -40px !important;
}

.injectionImg {
  height: 10px;
  width: 10px;
  margin: 0px 0px 13px -5px !important;
}

.jobDetailHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

.jobDetailPara {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #757575;
}

.jobDetailDiv {
  display: flex;
}



.btn.jobDetailBtn1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #2892D7;
  border: 1px solid #2892D7;
  border-radius: 4px;
  margin-right: 20px;
}

.btn.jobDetailBtn1:hover {
  color: #2892D7;
}

.btn.jobDetailBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  background: #2892D7;
  border-radius: 4px;
}

.btn.jobDetailBtn:hover {
  color: #FFFF;
}

.JobBtnDiv {
  display: flex;
  justify-content: space-between;
  padding: 3% 4% 0% 3%;
}

.ListSpacing {
  margin-right: 20%;
}

.JobMobileDesc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  display: none;
}

.JobInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #212121;
  width: 96%;
  padding-bottom: 2rem;
}

.JobInfoHead {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #202430;
  opacity: 0.5;
}

.JobDetailCard {
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
}

.JobDetailCardH {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #9E9E9E;
}

.JobDetailCardP {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #161616;
}

.colJobDetail {
  padding: 1rem 1rem;
  background: #F8F9FB;
  border-radius: 4px;
  padding-top: 2%;
  height: 60%;
  margin-right: 3%;
}

.colMargin {
  display: flex;
  justify-content: space-between;
}

.btnDotsList {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.btn.btnApplicant {
  height: 37px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  background: #2892D7;
  border-radius: 4px;
}

.btn.btnApplicant:hover {
  color: white;
}

.JobDetSearch {
  display: none;
}


.dropdown-toggle.btnDots {
  border: 1px solid #A8A8A8;
}

.imgAplicants {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.respJobDetailH {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #212121;
}

.respJobDetailsR {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #161616;
}

.respJobH {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #202430;
  opacity: 0.5;
}

.respJobP {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #212121;
}

@media screen and (max-width: 992px) {
  .jobDetailCont {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 620px) {
  .btn.btnApplicant {
    width: 95%;
    margin-bottom: 4%;
  }

  .dropdown-toggle.btnDots {
    margin-top: -18px;
  }


}

@media screen and (max-width: 600px) {

  .ulJobList {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  ul div.colMargin:first-child {
    order: 4;
  }

  ul div.colMargin:nth-child(2) {
    order: 3;
  }

  ul div.colMargin:nth-child(3) {
    order: 3;
  }

  ul div.colMargin:nth-child(4) {
    order: 1;
  }

  ul div.colMargin:nth-child(5) {
    order: 2;
  }

  .JobBtnDiv {
    padding: 3% 4% 0% 7%
  }


  .colJobDetail {
    width: 95% !important;
    background-color: transparent;
    position: absolute;
  }

  .ulTabs {
    justify-content: center;
  }

  .JobDetailCard {
    display: none;
  }

  .colMargin {
    display: block;
    margin-right: 5%;
    width: 100%;
  }

  .respDivJob {
    display: flex;
    border-bottom: 1px solid #F3F3F4;
  }

  .respJob {
    display: none;
  }

  .btn.jobDetailBtn {
    width: 100%;
  }

  .btn.jobDetailBtn1 {
    width: 100%;
  }

  .respBtn {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 90%;
    margin-bottom: 5%;
  }

  .respColJob {
    margin-top: 17% !important;
  }

  .JobInfo {
    margin-bottom: 15%;
  }
}

@media screen and (max-width: 422px) {
  .colMargin {
    margin-right: 4%;
  }
}

@media screen and (max-width: 405px) {
  .colMargin {
    margin-right: 3%;
  }
}

@media screen and (max-width: 389px) {
  .colMargin {
    margin-right: 2%;
  }
}

@media screen and (max-width: 530px) {
  .respColJob {
    margin-top: 20% !important;
  }

  .ListSpacing {
    margin-right: 15%;
  }
}

@media screen and (max-width: 444px) {
  .btn.btnApplicant {
    width: 95%;
    margin-bottom: 4%;
  }

  .dropdown-toggle.btnDots {
    margin-top: -18px;
  }

  .ListSpacing {
    margin-right: 12%;
  }
}

@media screen and (max-width: 460px) {
  .respColJob {
    margin-top: 24% !important;
  }
}

@media screen and (max-width: 400px) {
  .respColJob {
    margin-top: 26% !important;
  }

  .btn.btnApplicant {
    width: 95%;
    margin-bottom: 4%;
  }

  .ListSpacing {
    margin-right: 10%;
  }
}



@media screen and (max-width: 600px) {
  .JobMobileDesc {
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 355px) {
  .JobDetailCardH {
    font-size: 10px;
  }

  .JobDetailCardP {
    font-size: 10px;
  }

  .ListSpacing {
    margin-right: 7%;
  }
}