.info-application {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

span.carousel-control-next-icon {
  width: 20px;
}
button.btn.btnRating:hover {
  background: #2792d7;
}

span.carousel-control-prev-icon {
  width: 20px;
}
.paddingReview {
  text-align: initial;
}

.reviewText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #161616;
  padding: 10px;
}

.reviewModal {
  margin-top: 9rem;
}

.applicantInfoDiv {
  background-color: #f8f9fb;
}

.applicantPic {
  margin-top: 10%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.aplicant-name {
  text-align: center;
  margin-top: 5%;
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;
  color: #212121;
}

.ratings-h1 {
  width: 73px;
  height: 18px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #161616;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #161616;
  margin-left: 9px; */
}

.h1-applicant {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #9e9e9e;
}

.p-applicant {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #212121;
}

.div-sec-applicant {
  padding: 35% 0% 4% 5%;
}

.caroNumbeer {
  text-align: center;
  color: #74767a;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.apllyy.candidate.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  min-width: 100px !important;
  min-height: 37px;
  margin: 0px 5px;
  padding: 2px 17px;
}

.bio {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #212121;
}

/* .reesspo {
  text-align: center;
} */
.ellipseAtBio {
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exp-info {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
}

.exp2-info {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #757575;
  width: 135px;
}

.div-exp-main {
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
  padding-bottom: 1%;
}

.exp-Div {
  background: #f8f9fb;
  border-radius: 10px;
  padding: 3% 0% 0% 3%;
  margin: 3% 0%;
}

.qualities {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: black;
  border: 1px solid #e0e0e0;
  padding: 1% 2% 1% 2%;
  border-radius: 20px;
  margin-right: 2%;
  margin-bottom: 2%;
}

.btn.qualities {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212121;
  border: 1px solid #e0e0e0;
  padding: 2% 3% 2% 3%;
  border-radius: 20px;
  margin-right: 2%;
  margin-bottom: 2%;
}

.btn.btnJobOffer {
  background: #2892d7;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  width: 120px;
  height: 35px;
  margin: 0% 3% 0% 3%;
}

.btn.btnJobOffer:hover {
  color: white;
}

.blurReviews {
  -webkit-filter: blur(10px);
  pointer-events: none;
}
h1.ratings-h1 {
  display: flex;
}

span.ratResp {
  margin-left: 3px;
}
.btn.btnMsg {
  border: 1px solid #e0e0e0;
  padding-top: 3px;
  height: 36px;
}

.btn.btnMsginList {
  border: 1px solid #2892d7;
  padding-top: 3px;
  margin-left: 5px;
  height: 33px;
}

.msgTxt {
  display: none;
}

.respJobDiv2 {
  display: none;
}

.carousel_id {
  width: 175px;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
  max-height: 148px;
  /* width: 175px;
  height: auto;
  margin: 0 auto;
  object-fit: cover; */
}

.carousel-indicators [data-bs-target] {
  display: none !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  position: absolute;
  bottom: -40px;
  width: 20px;
}

.carousel-div {
  height: 300px;
  background-color: #f8f9fb;
  margin-top: 12%;
}

.carou-item {
  border-bottom: none !important;
}

@media screen and (max-width: 850px) {
  .btn.btnJobOffer {
    width: 90px;
  }
}

@media screen and (max-width: 694px) {
  .carousel-div {
    display: none;
  }

  .colApplicant {
    flex: auto !important;
  }

  .msgTxt {
    display: contents;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #212121;
  }

  .div-sec-applicant {
    padding: 4% 0% 1% 4%;
    margin: 0px 0px -200px 0px;
    background-color: #f8f9fb;
  }

  .card-body-app {
    border: none !important;
  }

  .applicantInfoDiv {
    background-color: white;
  }

  .btn.btnJobOffer {
    width: 70%;
  }

  .applicantPic {
    margin-top: 0%;
  }

  .info-application {
    flex-direction: row;
  }

  .ratResp {
    display: none;
  }

  .info-application {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0% 0% 6% 1%;
  }

  .respJobDiv {
    display: none;
  }

  .respJobDiv2 {
    display: block !important;
  }

  .btn.btnRating {
    width: 95% !important;
    margin-top: 3% !important;
    margin: 0 auto;
  }

  .reviewDiv {
    display: block !important;
  }
}

.reviewHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #757575;
}

.reviewDiv {
  display: flex;
  justify-content: space-between;
  padding: 3% 4% 3% 3%;
}

.ratingNo {
  font-weight: 600;
  font-size: 54px;
  line-height: 74px;
  color: #212121;
  margin-right: 10%;
}

.btn.btnRating {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff !important;
  background: #2892d7 !important;
  border-radius: 4px;
  margin-top: 7%;
  width: 141px;
  height: 35px;
}

.btn.btnRating:hover {
  color: white;
}

.applicantPic1 {
  margin-top: 3%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.toSeparator {
  font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #212121;
    margin-top: -10px;
    margin-right: 10px;
}

@media screen and (max-width: 484px) {
  .btn.btnJobOffer {
    width: 60% !important;
  }
}

@media screen and (max-width: 350px) {
  .btn.btnJobOffer {
    width: 57% !important;
  }
}